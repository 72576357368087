*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  margin: 0;
  padding: 0;
  background: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.aButton:focus {
  outline:none;
}

.aMap {
  outline: none;
}

