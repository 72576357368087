.App {
  max-width: 100vw;
}

.App::-webkit-scrollbar {
  display: none;
}

.displayWarning {
  position: absolute;
  background: black;
  opacity: 0.9;
  z-index: 20000;
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.displayWarningHeader {
  padding-top: 100px;
  opacity: 1;
  background: black;
  color: white;
}

@media only screen and (min-width: 800px) and (min-height: 600px) {
  .displayWarning {
    width:0;
    height:0;
    z-index: 0;
    display: none;
  }
}

.StreetScene {
  max-block-size: -webkit-fill-available;
  user-select: none;
}

.leftScrollButton {
  position: fixed;
  margin-left: 10px;
  padding-left: 2px;
  top: 50Vh;
  left: 0;
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  height: 35px;
  width: 35px;
  background: whitesmoke;
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
  border-radius: 35px;
  opacity: 0.7;
}

.rightScrollButton {
  position: fixed;
  margin-right: 10px;
  padding-right: 2px;
  top: 50vh;
  right: 0;
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  height: 35px;
  width: 35px;
  background: whitesmoke;
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
  border-radius: 35px;
  opacity: 0.7;
}

.rightScrollButtonHelp {
  position: absolute;
  padding-top: 5px;
  padding-left: 7px;
  bottom: 10px;
  right: 25vw;
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  height: 35px;
  width: 35px;
  background: whitesmoke;
  box-shadow: rgba(187, 55, 116, 0.5) 1px 1px 10px 4px;
  border-radius: 35px;
  z-index: 10003;
}

.RoomImage {
  width: 100vw;
  height: auto;
  object-fit: cover;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -ms-user-select: none;
}

/* Minimum aspect ratio */
@media (min-aspect-ratio: 16/9) {
  .RoomImage {
    height: 100vh;
    width: auto;
  }
  .modal-foreground {
    text-align: center;
  }
}


.infoDiv {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
}

.house-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  scrollbar-width: none;
}

.house-container::-webkit-scrollbar {
  display: none;
}

nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
}

.nav-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 230px;
  height: 30vh;
  opacity: 0.9;
}

.audio-content-modal {
  position: absolute;
}

.image-content-modal {
  position: absolute;
}

.text-content-modal {
  background: url('./images/grunge-paper-background-texture.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border: none;
  border-radius: 10px;
  box-shadow: 5px 10px 8px;
}

.TextModalConatiner {
  width: -webkit-fill-available;
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.TextModalContent {
  max-height: 68vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.modal-background {
  overflow-x: hidden;
  background: whitesmoke;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10001;
}

.modal-foreground {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100vh;
  width: 100vw;
  z-index: 10003;
}

.logo {
  position: absolute;
  left: 55px;
  margin: 0 auto;
  font-family: American Typewriter, serif;
  font-size: 30px;
  z-index: 1001;
}

.helpHome {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 40px;
  height: 40px;
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  background: white;
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
  border-radius: 40px;
  opacity: 0.8;
  font-size: 30px;
  font-weight: 900;
  font-family: American Typewriter, serif;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 30px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(8, 8, 8);
  font-family: American Typewriter, serif;
  font-size: 12px;
  width: 100vw;
  padding-top: 5px;
  text-align: center;
}

.footerTypist {
  z-index: 1002;
}

.HeritageFund {
  position: absolute;
  display: flex;
  left: 10px;
  top: -10px;
  border: none;
  background: transparent;
}

.social-icons {
  position: absolute;
  display: flex;
  right: 10px;
  top: 3px;
}

.social-icon {
  padding-right: 2px;
}

.menu-button {
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 27px;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: transparent;
}

.room-close-modal-button {
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 30px;
  height: 35px;
  width: 35px;
  background: whitesmoke;
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
  border-radius: 35px;
  z-index: 10003;
}

@-moz-document url-prefix() {
  .room-close-modal-button {
    height: 38px;
    width: 38px;
  }
}

.close-audio-modal-button {
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -20px;
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 30px;
  z-index: 10002;
}

.close-modal-button {
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 32px;
  height: 32px;
  background: whitesmoke;
  border-radius: 32px;
  z-index: 10002;
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
}

.close-about-modal-button {
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  background: whitesmoke;
  border-radius: 32px;
  z-index: 10002;
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
}

.close-help-modal-button {
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  background: whitesmoke;
  border-radius: 32px;
  z-index: 10003;
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
}

.close-resources-modal-button {
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  background: whitesmoke;
  border-radius: 32px;
  z-index: 10003;
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
}

.close-research-modal-button {
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  background: whitesmoke;
  border-radius: 32px;
  z-index: 10003;
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
}

.ul-menu,
.li-menu {
  margin: 0;
  padding: 0;
}

.ul-menu {
  margin-left: 22px;
  padding-left: 10px;
  position: absolute;
  top: 70px;
  width: 250px;
  background: whitesmoke;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
}

.li-menu {
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 10px;
  margin-top: 20px;
  margin-left: 10px;
  text-align: center;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.roomContainer {
  height:100%;
  width:100%;
  margin:0;
  padding:0;
  bottom:0;
  background-color:white;
  background-size:100% 100%;
}

.glyphicon {
  font-size: 20px;
}

.glyphicon.glyphicon-remove-sign {
  top:3px;
  right:0px;
}

.glyphicon.glyphicon-home {
  font-size: 25px;
  top:2px;
  right:2px;
}

.TypeWriterTextHeader {
  font-size: x-large;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}

.TypeWriterTextParagraph {
  font-size: large;
  font-family: 'Courier New', Courier, monospace;
}

.TextTypeWriterHeading {
  text-align: center;
  font-size: x-large;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}

.TextTypeWriterSubHeading {
  font-size: large;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}

.TextTypeWriterTextCenter {
  font-size: medium;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}

.aboutButton {
  background-color: transparent;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  border: none;
  color: black;
  padding: 0px;
}

.aboutDivContainer {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100vw;
}

.helpDivContainer {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 10002;
}

.resourcesDivContainer {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 10002;
}

.aboutModal {
  overflow-x: hidden;
  background: whitesmoke;
  position: absolute;
  border: none;
  box-shadow: rgba(187, 55, 116, 0.5) 0px 22px 70px 4px;
  border-radius: 10px;
  height: 50vh;
  width: 50vw;
  z-index: 10003;
  overflow: hidden;
}

.helpModal {
  overflow-x: hidden;
  background: whitesmoke;
  position: absolute;
  border: none;
  box-shadow: rgba(187, 55, 116, 0.5) 0px 22px 70px 4px;
  border-radius: 10px;
  height: 50vh;
  width: 50vw;
  z-index: 10002;
  overflow: hidden;
}

.resourcesModal {
  overflow-x: hidden;
  background: whitesmoke;
  position: absolute;
  border: none;
  box-shadow: rgba(187, 55, 116, 0.5) 0px 22px 70px 4px;
  border-radius: 10px;
  height: 55vh;
  width: 50vw;
  z-index: 10002;
  
}

.resourcesModalContent {
  overflow-y: scroll;
  scrollbar-width: none;
  z-index: 10002;
  max-height: -webkit-fill-available;
}

.HFH_Image {
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}

.resourcesHeader {
  padding-bottom: 15px;
  padding-left: 10px;
  text-align: center;
  font-family: American Typewriter, serif;
}

.resourcesImageExplain {
  text-align: center;
}

.container {
  max-width: 40vw;
}

.researchModal {
  background: rgb(161,161,161);
  background: radial-gradient(circle, rgba(161,161,161,1) 0%, rgba(0,0,0,1) 64%);
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10002;
  overflow-y: scroll;
  opacity: 0.95;
  scrollbar-width: none;
}

.ResearchTimeLineHeader {
  text-align: center;
  opacity: 1;
  color: white;
  font-family: American Typewriter, serif;
}

.TimeLineImage {
  text-align: center;
}

.helpModalWelcome {
  text-align: center;
  opacity: 1;
  font-family: American Typewriter, serif;
  padding-bottom: 20px;
}

.helpModalContent {
  position: relative;
  height: 30vh;
  width: 50vw;
  z-index: 10002;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.helpModalContent::-webkit-scrollbar {
  display: none;
}

.helpModalImage {
  position: relative;
  display: flex;
  max-block-size: -webkit-fill-available;
  max-width: -moz-available;
  z-index: 10002;
}

.helpModalImageRoom {
  position: relative;
  display: flex;
  width: 100%;
  max-width: -webkit-fill-available;
  max-width: -moz-available;
  z-index: 10002;
}

.helpModalText {
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  font-family: American Typewriter, serif;
  font-size: larger;
  text-align: center;
}

.modalButton {
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
  font-size: 2.5em;
  color: black;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: none;
  background: white;
}

.helpModalBackground {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  height: 100vh;
  width: 100vw;
  z-index: 10001;
  opacity: 0.9;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow: hidden;
  z-index: 1001;
}

.resourcesModalBackground {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  height: 100vh;
  width: 100vw;
  z-index: 10001;
  opacity: 0.9;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow: hidden;
  z-index: 1001;
}

.resourceExplain {
  position: fixed;
  bottom: 0;
  height: 50px;
  z-index: 2;
  width: -webkit-fill-available;
  background-color: black;
  opacity: 0.7;
  text-align: center;
}

.resourceExplainText {
  opacity: 1;
  color: antiquewhite;
  font-size: x-large;
}

.nextResource,
.prevResource {
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  z-index: 2;
  border-radius: 32px;
  box-shadow: rgb(0, 0, 0) 1px 1px 10px 4px;
  border: none;
  opacity: 0.4;
}

.nextResource {
  right: 10px;
  padding-left: 11px;
}

.prevResource {
  left: 10px;
  padding-right: 11px;
}

.resourceImage {
  position: absolute;
  width: -webkit-fill-available;
}

.aboutModalBackgroundLeft {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  height: 100vh;
  width: 50vw;
  z-index: 10001;
  opacity: 0.9;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow: hidden;
}

.aboutModalBackgroundRight {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  height: 100vh;
  width: 50vw;
  z-index: 10001;
  opacity: 0.9;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.aboutModalBackgroundLeft::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.aboutModalBackgroundRight::-webkit-scrollbar {
  display: none;
}

.aboutModalInfo {
  position: relative;
  overflow-y: scroll;
  height: 45vh;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 25px;
  font-family: American Typewriter, serif;
  font-size: larger;
}

.aboutModalTextHeader {
  text-align: center;
  margin: 10px;
  font-size: x-large;
  font-family: American Typewriter, serif;
}

.helpModalTextParagraph {
  font-size: larger;
  font-family: American Typewriter, serif;
  text-align: center;
}

.abounceLogoLink {
  padding-right: 10px;
  margin-top: -15px;
}

.nationalArchivesLogoLink {
  padding-right: 10px;
  margin-top: -4px;
}

.navLink {
  color: black;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;

}

@keyframes fadeIn {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0.1;
  }
  100% {
    opacity:1;
  }
}

.fade-out {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0.1;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0.1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0.1;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0.1;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0.1;
  }
}

